/** @jsx jsx */
import { jsx } from 'theme-ui'

import { MdDehaze, MdGridOn } from "react-icons/md"

const SearchOptions = (props) => {
    const {
      setSearchQuery,
      searchQuery,
      setLayout,
      layout,
    } = props

    return (
        <div sx={{
          display: "flex",
          flexDirection: "columns",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          backgroundColor: 'white',
          mb: 4,
          p: 3,
          gap: 3,
          // border: "2px solid rgba(0, 0, 0, .05)",
          borderRadius: 0,
        }}>
          <input
            type="text"
            placeholder="Chercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={(theme) => ({ 
              p: 2, 
              borderRadius: "4px",
              border: '2px solid rgba(0, 0, 0, .05)',
              backgroundColor: "rgba(255, 255, 255, 0.88)",
              flexGrow: "0",
              fontSize: 2,
              fontFamily: "body",
              transition: "flex-grow 0.4s ease-in-out",
              '::placeholder': {
                color: "rgba(0, 0, 0, .33)",
              },
              '&:focus': {
                flexGrow: "1",
                border: `2px solid ${theme.colors.primary}`,
                backgroundColor: "rgba(255, 255, 255, .88)",
                outline: 'none',
                color: "primary",
              }
            })}
          />

          <div sx={{ 
            display: "flex",
            borderRadius: 0,
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, .33)",
            overflow: "hidden",
            'button': {
              px: 3, py: 2,
              border: "none",
              cursor: "pointer",
              ':first-of-type' : {
                borderRight: "1px solid rgba(0, 0, 0, .33)",
              }
            },
            "svg": {
              marginBottom: "-2px",
            }
          }}>
            <button 
              onClick={() => setLayout("single")}
              sx={layout === "single" ? {
                backgroundColor: "primary",
                color: "white",
              } : {
                backgroundColor: "transparent",
                color: "grey",
              }}
            >
              <MdDehaze />
            </button>

            <button 
              onClick={() => setLayout("grid")}
              sx={layout === "grid" ? {
                backgroundColor: "primary",
                color: "white",
              } : {
                backgroundColor: "transparent",
                color: "grey",
              }}>
                <MdGridOn />
              </button>
          </div>
        </div>
    )
}

export default SearchOptions